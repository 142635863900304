const toUsPhone = (value) => {
  if (value) {
    const formattedValue = value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !formattedValue[2]
      ? formattedValue[1]
      : "(" +
          formattedValue[1] +
          ") " +
          formattedValue[2] +
          (formattedValue[3] ? "-" + formattedValue[3] : "");
  }
};

export default {
  toUsPhone,
};
