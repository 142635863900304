<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>
                  {{ $t("profile.title") }}
                </span>
              </label>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-10">
                <template>
                  <v-form>
                    <v-container>
                      <v-form class="mr-3">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="userDetail.company_name"
                              :error-messages="companyNameError"
                              :label="$t('profile.companyName')"
                              :placeholder="
                                $t('profile.companyNamePlaceholder')
                              "
                              @blur="$v.userDetail.company_name.$touch()"
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="userDetail.company_email"
                              :error-messages="companyEmailError"
                              :label="$t('profile.companyEmail')"
                              :placeholder="
                                $t('profile.companyEmailPlaceholder')
                              "
                              @blur="$v.userDetail.company_email.$touch()"
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              maxlength="14"
                              v-model="userDetail.company_phone"
                              :error-messages="companyPhoneError"
                              :label="$t('profile.companyPhone')"
                              :placeholder="
                                $t('profile.companyPhonePlaceholder')
                              "
                              @blur="$v.userDetail.company_phone.$touch()"
                              v-to-us-format
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="userDetail.representative_name"
                              :error-messages="representativeNameError"
                              :label="$t('profile.representativeName')"
                              :placeholder="
                                $t('profile.representativeNamePlaceholder')
                              "
                              @blur="$v.userDetail.representative_name.$touch()"
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              maxlength="14"
                              v-model="userDetail.representative_phone"
                              :error-messages="representativePhoneError"
                              :label="$t('profile.representativePhone')"
                              :placeholder="
                                $t('profile.representativePhonePlaceholder')
                              "
                              @blur="
                                $v.userDetail.representative_phone.$touch()
                              "
                              v-to-us-format
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="12">
                            <GooglePlace
                              :isRequired="true"
                              label="profile.address"
                              validationMsg="profile.validations.addressIsRequired"
                              :address="userDetail.address"
                              @onAddressSelect="addressHandler"
                            />
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              type="number"
                              v-model="userDetail.zip_code"
                              :error-messages="zipCodeError"
                              :label="$t('profile.zipCode')"
                              @blur="$v.userDetail.zip_code.$touch()"
                              :placeholder="$t('profile.zipCodePlaceholder')"
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="userDetail.state"
                              :error-messages="stateError"
                              :label="$t('profile.state')"
                              :placeholder="$t('profile.statePlaceholder')"
                              @blur="$v.userDetail.state.$touch()"
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="12">
                            <v-btn
                              :disabled="$v.$invalid && $v.$anyDirty"
                              large
                              color="green darkan-4 green-gradient white--text"
                              class="text-capitalize btn-submit"
                              @click="update"
                            >
                              {{ $t("profile.submit") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-form>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";

export default {
  components: {
    GooglePlace: () => import("@/components/common/GooglePlace"),
  },
  data() {
    return {
      address: null,
      userDetail: {},
      validAddress: true,
    };
  },
  validations: {
    userDetail: {
      company_name: {
        required,
      },
      company_email: {
        required,
        email,
      },
      company_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
      representative_name: {
        required,
      },
      representative_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
      zip_code: {
        required,
      },
      state: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    companyNameError() {
      const errors = [];
      if (!this.$v.userDetail.company_name.$dirty) return errors;
      !this.$v.userDetail.company_name.required &&
        errors.push(this.$t("profile.validations.companyNameIsRequired"));
      return errors;
    },

    companyEmailError() {
      const errors = [];
      if (!this.$v.userDetail.company_email.$dirty) return errors;
      !this.$v.userDetail.company_email.required &&
        errors.push(this.$t("profile.validations.companyEmailIsRequired"));
      !this.$v.userDetail.company_email.email &&
        errors.push(this.$t("profile.validations.companyEmailIsInvalid"));
      return errors;
    },

    companyPhoneError() {
      const errors = [];
      if (!this.$v.userDetail.company_phone.$dirty) return errors;
      !this.$v.userDetail.company_phone.required &&
        errors.push(this.$t("profile.validations.companyPhoneIsRequired"));
      !this.$v.userDetail.company_phone.usFormat &&
        errors.push(this.$t("profile.validations.companyPhoneIsInvalid"));
      return errors;
    },

    representativeNameError() {
      const errors = [];
      if (!this.$v.userDetail.representative_name.$dirty) return errors;
      !this.$v.userDetail.representative_name.required &&
        errors.push(
          this.$t("profile.validations.representativeNameIsRequired")
        );
      return errors;
    },

    representativePhoneError() {
      const errors = [];
      if (!this.$v.userDetail.representative_phone.$dirty) return errors;
      !this.$v.userDetail.representative_phone.required &&
        errors.push(
          this.$t("profile.validations.representativePhoneIsRequired")
        );
      !this.$v.userDetail.representative_phone.usFormat &&
        errors.push(
          this.$t("profile.validations.representativePhoneIsInvalid")
        );
      return errors;
    },

    zipCodeError() {
      const errors = [];
      if (!this.$v.userDetail.zip_code.$dirty) return errors;
      !this.$v.userDetail.zip_code.required &&
        errors.push(this.$t("profile.validations.zipIsRequired"));
      return errors;
    },

    stateError() {
      const errors = [];
      if (!this.$v.userDetail.state.$dirty) return errors;
      !this.$v.userDetail.state.required &&
        errors.push(this.$t("profile.validations.stateIsRequired"));
      return errors;
    },
  },
  mounted() {
    if (this.user && this.user.user_detail) {
      this.userDetail = this.user.user_detail;
      this.userDetail.representative_phone = numberUtility.toUsPhone(
        this.userDetail.representative_phone
      );
      this.userDetail.company_phone = numberUtility.toUsPhone(
        this.userDetail.company_phone
      );
    }
  },
  methods: {
    ...mapActions({
      updateProfile: "user/updateProfile",
    }),
    addressHandler(payload) {
      this.userDetail.address = payload.address;
      this.userDetail.state = payload.state;
      this.userDetail.zip_code = payload.zip;
      this.validAddress = payload.valid;
    },
    async update() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.validAddress) {
        this.$store.commit("setLoader", true);
        try {
          await this.updateProfile(this.userDetail);
        } catch ({ message }) {
          console.log("sdfdsf");
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
  },
};
</script>
